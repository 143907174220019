import React from 'react';
import { useSpring, animated } from 'react-spring';

const SvgComponent = ({
  svgRef,
  title,
  stroke = `currentColor`,
  hovered,
  ...props
}) => {
  const spring = useSpring({
    transform: `rotate(${hovered ? 90 : 0}deg)`,
  });
  return (
    <animated.svg
      width="1em"
      height="1em"
      viewBox="0 0 19.424 19.424"
      ref={svgRef}
      style={{ ...spring }}
      {...props}
    >
      <title>{title}</title>
      <g fill="none" stroke={stroke} strokeWidth={4}>
        <path d="M9.712 0v19.424M19.424 9.712H0" />
      </g>
    </animated.svg>
  );
};

const ForwardRef = React.forwardRef((props, ref) => (
  <SvgComponent svgRef={ref} {...props} />
));
export default ForwardRef;
