import React from 'react';
import { withTheme } from 'styled-components';
import Img from 'components/Img';
import LineHeading from 'components/LineHeading';
import { H3 } from 'components/typography/headings';
import { Link } from 'gatsby';
import {
  ParallaxChild,
  ParallaxContainer,
  withParallaxProvider,
} from 'hooks/useParallax';
import { Text, Box } from 'system';
import * as A from 'system/animated';
import { Column, Container, Row, Section } from 'system/layout';
import { h2 } from 'theme/typography';
import resolveLink from 'utils/resolveLink';
import { animated } from 'react-spring';
import PlusLink from 'components/PlusLink';
import { withGlobals } from 'globals/index';

const CUT_HEIGHT = [80, 200, 450, 550, 550];
const CUT_HEIGHT_BOTTOM = [300, 350, 450, 550, 550];

function BG({ theme, scrollTop }) {
  const pts = scrollTop.to((st) => {
    const t = Math.round(st * 0.1);
    const pos = t > 0 ? 0 : Math.abs(t, 0) || 0;
    return `100,${pos} 100,100 0,100`;
  });

  const ptsBottom = scrollTop.to((st) => {
    const t = Math.round(st * 0.1);
    const pos = Math.min(t > 0 ? 0 : Math.abs(t, 0), 100);
    return `0,0 100,0 100,${pos}`;
  });

  return (
    <A.Absolute
      zIndex={0}
      top={-30}
      left={0}
      width={1}
      display={`flex`}
      flexDirection={`column`}
      height={CUT_HEIGHT_BOTTOM.map(
        (h, idx) => `calc(100% + ${h - theme.sectionPadding.y[idx] * 2}px)`
      )}
    >
      <Box width={1} height={CUT_HEIGHT}>
        <Box
          as={`svg`}
          width={1}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
        >
          <animated.polygon fill={theme.colors.yellow} points={pts} />
        </Box>
      </Box>
      <Box width={1} flex={1} bg={theme.colors.yellow} />
      <Box width={1} height={CUT_HEIGHT_BOTTOM}>
        <Box
          as={`svg`}
          width={1}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
        >
          <animated.polygon fill={theme.colors.yellow} points={ptsBottom} />
        </Box>
      </Box>
    </A.Absolute>
  );
}

function Case({
  theme,
  id,
  listingTitle,
  listingImage,
  layout = `ltr`,
  client: { name, logo },
}) {
  const link = resolveLink(id);
  const { url, title } = link;

  return (
    <Link
      to={url}
      title={title}
      css={`
        .listingImage img {
          transform: scale(1);
          transition: transform 0.2s ease-in;
        }
        :hover .listingImage img {
          transform: scale(1.025);
          transition: transform 0.2s ease-out;
        }
        :focus .listingImage img {
          transform: scale(1.05);
          transition: transform 0.2s ease-out;
        }
      `}
    >
      <ParallaxContainer as={Row} normal={[0.05, 0.05, 0.15]}>
        <ParallaxChild
          as={Column}
          width={[1, 1, 1 / 2]}
          ml={[
            0,
            0,
            layout === `rtl` ? `${(5 / 12) * 100}%` : `${(1 / 12) * 100}%`,
          ]}
          mt={[30]}
          mb={[-40, -50, -60, -70]}
          zIndex={1}
          offsetY={0.05}
        >
          <Text
            {...h2}
            style={{ textDecoration: `underline` }}
            color={theme.colors.blue}
            dangerouslySetInnerHTML={{ __html: listingTitle || title }}
          />
        </ParallaxChild>
        <Column width={1} mb={[30, 40, 50, 60]}>
          <Row alignItems={`center`}>
            <ParallaxChild
              as={Column}
              order={[2, 2, layout === `ltr` ? 1 : 2]}
              width={[1 / 2, 1 / 2, 1 / 6]}
              ml={[
                `${(1 / 4) * 100}%`,
                `${(1 / 4) * 100}%`,
                `${(1 / 12) * 100}%`,
              ]}
              mt={20}
              offsetY={0.1}
              textAlign={`center`}
            >
              <Img {...logo} alt={name} />
            </ParallaxChild>
            <ParallaxChild
              as={Column}
              order={[1, 1, layout === `ltr` ? 2 : 1]}
              width={[1, 1, 7 / 12]}
              ml={[0, 0, `${(1 / 12) * 100}%`]}
              offsetY={0.15}
              className="listingImage"
            >
              <Img
                {...listingImage}
                alt={listingImage.alt || listingTitle || title}
                imgStyle={{
                  transition: `transform 0.2s ease-in`,
                }}
              />
            </ParallaxChild>
          </Row>
        </Column>
      </ParallaxContainer>
    </Link>
  );
}

function CaseStudyListingBlock({
  theme,
  callout,
  heading,
  cases,
  ref,
  parallax,
  inView,
  showAll = false,
  locale,
  ...rest
}) {
  return (
    <Section ref={ref} position={`relative`} {...rest}>
      <BG theme={theme} scrollTop={parallax.scrollTop} />
      <Container>
        <ParallaxContainer as={Row} normal={[0.25, 0.25, 0.15]}>
          {callout && (
            <ParallaxChild
              as={Column}
              width={[1, 1, 9 / 12]}
              offsetY={0.05}
              mb={[20, 30, 40]}
            >
              <LineHeading label={callout} inView={inView} />
            </ParallaxChild>
          )}
          {heading && (
            <ParallaxChild as={Column} width={1} offsetY={0.075}>
              <H3 as={`h2`} dangerouslySetInnerHTML={{ __html: heading }} />
            </ParallaxChild>
          )}
        </ParallaxContainer>
        {cases &&
          cases.length > 0 &&
          cases.map((c, idx) => (
            <Case
              key={`case-${idx}`}
              index={idx}
              theme={theme}
              layout={idx % 2 === 0 ? `ltr` : `rtl`}
              {...c}
            />
          ))}
        {showAll && (
          <ParallaxContainer as={A.Flex} normal={[0.75, 0.6, 0.5]}>
            <ParallaxChild
              as={A.Flex}
              offsetY={0.15}
              mt={[40, 40, 0]}
              mb={[40, 40, 30]}
              width={1}
              justifyContent={[`flex-end`, `flex-end`, `center`]}
            >
              <PlusLink
                label={
                  locale === `fr`
                    ? `Plus d’études de cas`
                    : `See More Case Studies`
                }
                link={{
                  url: locale === `fr` ? `work` : `work`,
                }}
              />
            </ParallaxChild>
          </ParallaxContainer>
        )}
      </Container>
    </Section>
  );
}

export default withGlobals(
  withTheme(withParallaxProvider(CaseStudyListingBlock))
);
