import Plus from 'components/icons/Plus';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Text } from 'system';
import { Text as AnimatedText } from 'system/animated';

function PlusLink({
  theme,
  color,
  iconColor,
  label,
  link,
  style = {},
  onClick = undefined,
  target = null,
  ...rest
}) {
  const hasLink = onClick === undefined;
  const isOutBound = typeof link === `string`;
  const linkProps = {
    as: Link,
    to: link && link.url ? `${link.url}` : `/`,
    title: label,
  };
  const outboundLinkProps = {
    as: OutboundLink,
    href: link,
    title: label,
    rel: `noopener noreferrer`,
    target,
  };
  const noLinkProps = { as: `button` };
  let propsToUse;

  const [hovered, setHovered] = useState(false);

  switch (true) {
    case hasLink && isOutBound:
      propsToUse = outboundLinkProps;
      break;
    case hasLink && !isOutBound:
      propsToUse = linkProps;
      break;
    default:
      propsToUse = noLinkProps;
      break;
  }
  return (
    <AnimatedText
      fontSize={[14, 17, 19, 21]}
      fontFamily={theme.fonts.headings}
      fontWeight={`bold`}
      color={color || theme.colors.black}
      bg={`transparent`}
      p={0}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      style={{ ...style, border: `none`, cursor: `pointer` }}
      {...propsToUse}
      {...rest}
    >
      <Text
        as={`span`}
        style={{
          textTransform: `uppercase`,
          textDecoration: `underline`,
        }}
        mr={[1, 2, 3, 4, 5]}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <Plus
        stroke={iconColor || theme.colors.blue}
        hovered={hovered}
        aria-hidden="true"
      />
    </AnimatedText>
  );
}

export default withTheme(PlusLink);
